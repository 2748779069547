<template>
	<div>
		<div class="sc-width">
			<probm :probm="probm"></probm>
		</div>
	</div>
</template>

<script>
	import probm from "@/components/probm/probm"
	import moment from "moment"
	import util from "@/assets/script/util.js"
	export default {
		components:{
			probm
		},
		created() {
			let id = this.$route.query.id
			if(!this.$route.query.type) {
				this.getNewDeal(id)
			}else{
				this.getTjDeal(id)
			}
			
		},
		data() {
			return {
				probm:{}
			}
		},
		methods:{
			// 查询新闻详情页
			async getNewDeal(id) {
				let url = this.$api.index.news_detail
				let res = await this.$axios.get(url,{params:{id}})
				if(res.code) {
					res.data.inputtime = moment(parseInt(res.data.inputtime*1000)).format("YYYY-MM-DD")
					if(res.data.subject) {
						res.data.subject = util.setContent(res.data.subject)
					}
					if(res.data.content) {
						res.data.content = util.setContent(res.data.content)
					}
					
					this.probm = res.data
				}
			},
			// 查询党建详情页
			getTjDeal(id) {
				let url = this.$api.about.work_detail
				this.$axios.get(url,{params:{id}}).then(res=>{
					if(res.code) {
						res.data.inputtime = moment(parseInt(res.data.inputtime*1000)).format("YYYY-MM-DD")
						if(res.data.subject) {
							res.data.subject = util.setContent(res.data.subject)
						}
						if(res.data.content) {
							res.data.content = util.setContent(res.data.content)
						}
						this.probm = res.data
					}
				})
			},
			
		}
	}
</script>

<style lang="less" scoped>
	/deep/.time p:last-child {
		margin-left: 0;
	}
</style>